// import { Slider } from "antd";
import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Navigation from "../../../navigation";
import glass from "../../../../images/magnifying_glass.svg";
import { SearchBar } from "../../../navigation/style";
import { useHistory } from "react-router-dom";
import party from "party-js";
import ReactGA from "react-ga4";
import useDebounce from "../../../../hooks/useDebounce";
import { measurementId } from "../../../../firebaseSetup";
import { analytics } from "../../../../firebaseSetup";
import {
  ContentDiv,
  Title,
  BackgroundDiv,
  Subtitle,
  Heading,
  Outer,
} from "./style";
import useAppzi from "../../../../hooks/useAppzi";
import { Button } from "../../../../styles/shared";
export default function Track3(props) {
  useAppzi("rddQu");
  const [contentRef, setContentRef] = useState();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);
  const history = useHistory();
  let application;
  let position;
  let hash;
  const backSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-miterlimit="10"
        stroke-width="32"
        d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
      />
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="m296 352l-96-96l96-96"
      />
    </svg>
  );

  useEffect(() => {
    if (debouncedSearch !== "") {
      analytics.logEvent("search");
      history.push({
        pathname: "/mainfeed",
        state: { searchValue: debouncedSearch },
      });
    }
  }, [debouncedSearch]);
  useEffect(() => {
    ReactGA.initialize(measurementId);
    ReactGA.send("page_view");
  }, []);
  useEffect(() => {
    if (contentRef) {
      party.confetti(contentRef, {
        count: party.variation.range(45, 55),
        spread: party.variation.range(15, 50),
        speed: 650,
        color: () =>
          party.Color.fromHsl(party.random.randomRange(0, 360), 100, 50),
        shapes: [
          "square",
          "rectangle",
          "roundedSquare",
          "roundedRectangle",
          "circle",
        ],
      });
    }
  }, [contentRef]);

  if (JSON.stringify(props) === "{}") {
    return <Redirect to="/tracker" />;
  } else if (props.location.state == undefined) {
    return <Redirect to="/tracker" />;
  } else {
    application = JSON.parse(props.location.state);
    position = application.position ? application.position : "";
    hash = position ? (
      <span style={{ color: "#2071c7" }}>{"\t" + position}</span>
    ) : (
      <span>a new job</span>
    );
    const handleSearch = (e) => {
      setSearch(e.target.value);
    };

    return (
      <Fragment>
        <Outer>
          <Navigation>
            <SearchBar image={glass} onChange={(e) => handleSearch(e)} />
          </Navigation>
          <BackgroundDiv>
            <ContentDiv ref={setContentRef}>
              <Heading>
                <Title style={{ margin: "50px 0 20px 0" }}>Congrats!</Title>
                <Subtitle>
                  Onto the next step in this journey! <br />
                  Your journey will help inspire others if you share your
                  progress.
                </Subtitle>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                    marginBottom: "25px",
                  }}
                >
                  <Button secondary onClick={() => history.push("/tracker")}>
                    Go back to your applications
                  </Button>
                  <Button
                    primary
                    style={{ marginLeft: "50px" }}
                    onClick={() => {
                      analytics.logEvent("stared_status_from_trackr");
                      history.push({
                        pathname: "/mainfeed",
                        state: {
                          application: JSON.stringify(application),
                        },
                      });
                    }}
                  >
                    Share your journey with others
                  </Button>
                </div>
              </Heading>
            </ContentDiv>
          </BackgroundDiv>
        </Outer>
      </Fragment>
    );
  }
}
