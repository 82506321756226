// import React, { Fragment } from "react";
import Navigation from "../../../navigation";
import glass from "../../../../images/magnifying_glass.svg";
import { SearchBar } from "../../../navigation/style";
import backarrow from "../../../../images/backarrow.svg";
import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { analytics } from "../../../../firebaseSetup";
import useDebounce from "../../../../hooks/useDebounce";
import ReactGA from "react-ga4";
import { measurementId } from "../../../../firebaseSetup";

import {
  ContentDiv,
  Title,
  Heading,
  Subtitle,
  Input,
  SubmitBtn,
  BackgroundDiv,
  BackSvg,
  Outer,
} from "./style";

import useAppzi from "../../../../hooks/useAppzi";
import firebase from "../../../../firebaseSetup";

export default function Track1() {
  useAppzi("rddQu");
  if (window.location.hostname === "localhost") {
    firebase.functions().useEmulator("localhost", 5001);
  }
  const backSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-miterlimit="10"
        stroke-width="32"
        d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
      />
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="m296 352l-96-96l96-96"
      />
    </svg>
  );

  const [link, setLink] = useState(undefined);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);
  const [loading, setLoading] = useState(undefined);
  const history = useHistory();
  const linkedInScraper = firebase
    .app()
    .functions("us-central1")
    .httpsCallable("linkedInScraper");
  const indeedScraper = firebase.functions().httpsCallable("indeedScraper");
  const monsterScraper = firebase.functions().httpsCallable("monsterScraper");
  const zipRecruiterScraper = firebase
    .functions()
    .httpsCallable("zipRecruiterScraper");
  const googleScraper = firebase.functions().httpsCallable("googleScraper");
  const greenhouseScraper = firebase
    .functions()
    .httpsCallable("greenhouseScraper");
  const leverScraper = firebase.functions().httpsCallable("leverScraper");
  const ashbyScraper = firebase.functions().httpsCallable("ashbyScraper");
  useEffect(() => {
    if (debouncedSearch !== "") {
      analytics.logEvent("search");
      history.push({
        pathname: "/mainfeed",
        state: { searchValue: debouncedSearch },
      });
    }
  }, [debouncedSearch]);

  const chooseScraper = (link) => {
    const { hostname } = new URL(link);
    console.log(link, hostname);
    if (hostname === "www.linkedin.com") {
      return linkedInScraper({
        link: link,
      });
    }
    if (hostname === "www.indeed.com") {
      return indeedScraper({
        link: link,
      });
    }
    if (hostname === "www.monster.com") {
      return monsterScraper({
        link: link,
      });
    }
    if (hostname === "www.ziprecruiter.com") {
      return zipRecruiterScraper({
        link: link,
      });
    }
    if (hostname === "www.google.com") {
      return googleScraper({
        link: link,
      });
    }
    if (hostname === "boards.greenhouse.io") {
      return greenhouseScraper({ link: link });
    }
    if (hostname === "jobs.lever.co") {
      return leverScraper({ link: link });
    }
    if (hostname === "jobs.ashbyhq.com") {
      return ashbyScraper({ link: link });
    }
  };
  const isLinkedInScrapable = (link) => {
    return !link.includes("/search?") && !link.includes("/recommended/");
  };
  const isIndeedScrapable = (link) => {
    return link.includes("viewjob?");
  };
  // remove www, substitute "includes"
  const isInAvailableDomains = (link) => {
    const availableDomains = [
      "www.linkedin.com",
      "www.indeed.com",
      "www.monster.com",
      "www.ziprecruiter.com",
      "www.google.com",
      "boards.greenhouse.io",
      "jobs.lever.co",
      "jobs.ashbyhq.com",
    ];
    try {
      const { hostname } = new URL(link);
      let specialCaseFlag = false;
      let specialCase;
      console.log(hostname, link);
      if (hostname === "www.linkedin.com") {
        specialCaseFlag = true;
        specialCase = isLinkedInScrapable(link);
      }
      if (hostname === "www.indeed.com") {
        specialCaseFlag = true;
        specialCase = isIndeedScrapable(link);
      }
      console.log(specialCaseFlag, specialCase);
      if (specialCaseFlag) {
        return specialCase && availableDomains.includes(hostname);
      }
      return availableDomains.includes(hostname);
    } catch (err) {
      console.log(err);
      if (window.location.href.includes("new")) {
        history.push({
          pathname: "/tracker/new/information",
          state: {
            link: link,
            error:
              "Sorry, we were unable to grab the application information. Please type in the application information.",
          },
        });
      }
    }
  };
  async function handleSubmit(event) {
    var data = {};
    event.preventDefault();
    if (isInAvailableDomains(link)) {
      console.log("entered");
      setLoading(true);
      await chooseScraper(link)
        .then((result) => {
          console.log(result, result.data);
          data = { ...result.data.data };
          data.link = link;
          console.log(data);
          setLoading(false);
          if (window.location.href.includes("new")) {
            history.push({
              pathname: "/tracker/new/information",
              state: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (window.location.href.includes("new")) {
            history.push({
              pathname: "/tracker/new/information",
              state: {
                link: link,
                error:
                  "Sorry, we were unable to grab the application information. Please type in the application information.",
              },
            });
          }
        });
    } else {
      if (window.location.href.includes("new")) {
        history.push({
          pathname: "/tracker/new/information",
          state: {
            link: link,
            error:
              "Sorry, we were unable to grab the application information. Please type in the application information.",
          },
        });
      }
    }
  }
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    ReactGA.initialize(measurementId);
    ReactGA.send("page_view");
  }, []);
  return (
    <Fragment>
      <Outer>
        <Navigation>
          <SearchBar image={glass} onChange={(e) => handleSearch(e)} />
        </Navigation>
        <BackgroundDiv>
          <ContentDiv>
            <a
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
                color: "#677394",
                margin: "10px",
              }}
              onClick={() => history.push("/tracker")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32px"
                height="32px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="#5a5a5a"
                  stroke-miterlimit="10"
                  stroke-width="32"
                  d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
                />
                <path
                  fill="none"
                  stroke="#5a5a5a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                  d="m296 352l-96-96l96-96"
                />
              </svg>
            </a>

            <Heading style={{ marginTop: "40px" }}>
              <Title>Add a new application </Title>
              <Subtitle>We’re excited to see where this takes you!</Subtitle>
              <form onSubmit={(e) => handleSubmit(e)}>
                <Input
                  placeholder="https://link_to_your_application_here.com"
                  onChange={(e) => setLink(e.target.value)}
                />
                {loading ? (
                  <Loader type="ThreeDots" color="#175596" />
                ) : (
                  <SubmitBtn type="submit">Submit link</SubmitBtn>
                )}
              </form>
            </Heading>
          </ContentDiv>
        </BackgroundDiv>
      </Outer>
    </Fragment>
  );
}
