import React, { Fragment, useContext } from "react";
import Navigation from "../../../navigation";
import glass from "../../../../images/magnifying_glass.svg";
import { SearchBar } from "../../../navigation/style";
import ReactGA from "react-ga4";
import backarrow from "../../../../images/backarrow.svg";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ButtonDropDown from "../applicationfeed/ButtonDropDown";
import useDebounce from "../../../../hooks/useDebounce";
import { ApplicationButton } from "../../../../styles/shared";
import { analytics } from "../../../../firebaseSetup";
import { measurementId } from "../../../../firebaseSetup";

import {
  BackgroundDiv,
  ContentDiv,
  BackBtn,
  BackSvg,
  Heading,
  Title,
  Subtitle,
  Input,
  Input2,
  TextAreaDiv,
  Slider,
  SliderDiv,
  Label,
  SubmitBtn,
  ApplicationButtonContainer,
  Textarea,
} from "./style";
import { SubmitButton } from "../../../../styles/shared";
import useAppzi from "../../../../hooks/useAppzi";
import firebase, { auth, firestore } from "../../../../firebaseSetup";
import { AuthenticationContext } from "../../../../AuthenticationContext";

export default function Track2(props) {
  useAppzi("rddQu");
  const { authentication, setAuthentication } = useContext(
    AuthenticationContext
  );
  const backSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-miterlimit="10"
        stroke-width="32"
        d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
      />
      <path
        fill="none"
        stroke="#5a5a5a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="m296 352l-96-96l96-96"
      />
    </svg>
  );

  const buttonSvg1 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g transform="translate(24 0) scale(-1 1)">
        <path
          fill="white"
          d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"
        />
        <path
          fill="white"
          d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"
        />
      </g>
    </svg>
  );
  const buttonSvg2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m4 12l6 6L20 6"
      />
    </svg>
  );
  const buttonSvg3 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="white"
        d="M14.755 15a2.249 2.249 0 0 1 2.248 2.249v.575c0 .895-.32 1.76-.9 2.439C14.532 22.096 12.145 23 9 23s-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.434v-.578A2.249 2.249 0 0 1 3.253 15h11.502Zm0 1.5H3.252a.749.749 0 0 0-.75.749v.578c0 .535.192 1.053.54 1.46c1.253 1.468 3.22 2.214 5.957 2.214c2.739 0 4.706-.746 5.963-2.214a2.25 2.25 0 0 0 .54-1.463v-.575a.749.749 0 0 0-.748-.749Zm4.3-15.096a.75.75 0 0 1 1.023.279A12.694 12.694 0 0 1 21.75 8c0 2.254-.586 4.424-1.683 6.336a.75.75 0 1 1-1.301-.746A11.194 11.194 0 0 0 20.25 8c0-1.983-.513-3.89-1.475-5.573a.75.75 0 0 1 .28-1.023ZM9 3.004a5 5 0 1 1 0 10a5 5 0 0 1 0-10Zm6.589.396a.75.75 0 0 1 1.023.28A8.713 8.713 0 0 1 17.75 8a8.72 8.72 0 0 1-1.143 4.328a.75.75 0 1 1-1.304-.743A7.213 7.213 0 0 0 16.25 8a7.213 7.213 0 0 0-.942-3.578a.75.75 0 0 1 .28-1.022ZM9 4.505a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7Z"
      />
    </svg>
  );
  const buttonSvg4 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="white"
        d="M1023.31 473.28c-1.36-11.312-5.614-21.713-12.095-30.465h.16l-.88-.88c-3.28-4.32-7.12-8.128-11.408-11.504L831.935 262.047V158.783c0-17.68-14.32-32-32-32H698.607l-116.8-99.711c-36.223-36.224-99.504-36.289-135.776 0l-116.4 99.711H223.935c-17.68 0-32 14.32-32 32V264.16L28.815 427.28c-17.343 11.472-28.88 31.088-28.88 53.344v479.504c0 35.184 28.817 64 64 64h896c35.184 0 64-28.816 64-64v-478.24c.224-2.88-.064-5.76-.624-8.607zM268.865 725.503L63.937 915.582V544.686zm61.697 29.999a31.634 31.634 0 0 0 11.377-8.224c1.712-1.935 3.056-4.063 4.224-6.255l137.904-127.936c7.248-5.84 15.84-8.913 24.88-8.913c9.183 0 18.367 3.216 24.527 8l383.84 347.936H110.002zm427.777-25.776l201.6-178.896v361.632zm139.599-313.104h-.976l54.896 55.792l-119.92 106.432V350.638zM491.283 72.318c6.064-6.032 14.095-9.376 22.623-9.376c8.56 0 16.592 3.344 22.656 9.376l64.624 54.464H426.754zm276.655 118.464V635.63l-57.312 50.88l-136.32-123.568c-37.68-29.536-91.775-30.817-131.68 1.376l-126.624 117.44l-60.064-53.008V190.782zM103.777 442.813l88.16-88.129V572.3L75.313 469.404l26.992-26.592h1.472z"
      />
    </svg>
  );
  const buttonSvg7 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill="white" d="M7 10h10v4H7z" />
      <path
        fill="white"
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"
      />
    </svg>
  );
  const buttonSvg6 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        fill="white"
        d="M256 16C123.452 16 16 123.452 16 256s107.452 240 240 240s240-107.452 240-240S388.548 16 256 16Zm147.078 387.078a207.253 207.253 0 1 1 44.589-66.125a207.332 207.332 0 0 1-44.589 66.125Z"
      />
      <path
        fill="white"
        d="M152 200h40v40h-40zm168 0h40v40h-40zm-64 88a88.1 88.1 0 0 0-88 88h32a56 56 0 0 1 112 0h32a88.1 88.1 0 0 0-88-88Z"
      />
    </svg>
  );
  const buttonSvg5 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="white"
        d="M11 6h3l3.29-3.3a1 1 0 0 1 1.42 0l2.58 2.59a1 1 0 0 1 0 1.41L19 9h-8v2a1 1 0 0 1-1 1a1 1 0 0 1-1-1V8a2 2 0 0 1 2-2m-6 5v4l-2.29 2.29a1 1 0 0 0 0 1.41l2.58 2.59a1 1 0 0 0 1.42 0L11 17h4a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h-7v1a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V9Z"
      />
    </svg>
  );
  const statusConverter = [
    "Interested",
    "Applied",
    "Interviewed",
    "Offered",
    "Accepted",
    "Rejected",
    "Declined",
  ];
  const colors = [
    "#6175A9",
    "#61A5A9",
    "#A21660",
    "#9FA216",
    "#61A975",
    "#A99161",
    "#A96199",
  ];
  const svgOrder = [
    buttonSvg1,
    buttonSvg2,
    buttonSvg3,
    buttonSvg4,
    buttonSvg5,
    buttonSvg6,
    buttonSvg7,
  ];

  const [slider, setSlider] = useState();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);
  const [stage, setStage] = useState();
  const history = useHistory();
  const state = props.location.state;
  let error = state && state.error ? state.error : "";
  const link = useFormInput(state ? state.link : "");
  const role = useFormInput(state ? state.title : "");
  const company = useFormInput(state ? state.company : "");
  const deadline = useFormInput("");
  const location = useFormInput(state ? state.place : "");
  const description = useFormInput(state ? state.description : "");
  const [drop, setDrop] = useState(false);
  let jobsRef;
  useEffect(() => {
    ReactGA.initialize(measurementId);
    ReactGA.send("page_view");
  }, []);
  useEffect(() => {
    if (debouncedSearch !== "") {
      analytics.logEvent("search");
      history.push({
        pathname: "/mainfeed",
        state: { searchValue: debouncedSearch },
      });
    }
  }, [debouncedSearch]);
  const downArrowSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="black"
        d="M831.872 340.864L512 652.672L192.128 340.864a30.592 30.592 0 0 0-42.752 0a29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728a30.592 30.592 0 0 0-42.752 0z"
      />
    </svg>
  );

  if (JSON.stringify(props) === "{}") {
    return <Redirect to="/tracker" />;
  } else {
    jobsRef = firestore.collection(`jobs/${authentication["uid"]}/jobs`);
    console.log(state);
    console.log(description);
    async function handleSubmit(e) {
      e.preventDefault();
      console.log(stage);
      console.log(deadline);
      console.log(auth.currentUser.uid, authentication["uid"]);
      jobsRef.add({
        uid: auth.currentUser.uid,
        position: role.value ? role.value : "",
        company: company.value ? company.value : "",
        app_status: slider ? slider : "0",
        link: link.value ? link.value : "",
        deadline: deadline.value
          ? firebase.firestore.Timestamp.fromDate(new Date(deadline.value))
          : firebase.firestore.Timestamp.fromDate(new Date(0)),
        location: location.value ? location.value : "",
        description: description.value ? description.value : "",
        notes: "",
        date_updated: firebase.firestore.Timestamp.now(),
      });

      history.push({
        pathname: "/tracker/new/confirm",
        state: JSON.stringify({
          company: company.value,
          position: role.value,
          app_status: slider ? slider : "0",
        }),
      });
    }

    function handleSlider(e) {
      var rangeValues = {
        0: "Interested",
        1: "Applied",
        2: "Interview",
        3: "Offer",
      };
      setSlider(e.target.value);
      setStage(rangeValues[e.target.value]);
      console.log(slider);
      console.log(stage);
    }
    const handleSearch = (e) => {
      setSearch(e.target.value);
    };

    return (
      <Fragment>
        <div>
          <Navigation>
            <SearchBar image={glass} onChange={(e) => handleSearch(e)} />
          </Navigation>
          <BackgroundDiv drop={drop}>
            <ContentDiv style={{ marginTop: "20px" }}>
              <BackBtn>
                <svg
                  onClick={() => history.push("/tracker/new")}
                  xmlns="http://www.w3.org/2000/svg"
                  width="32px"
                  height="32px"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="#5a5a5a"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M256 64C150 64 64 150 64 256s86 192 192 192s192-86 192-192S362 64 256 64Z"
                  />
                  <path
                    fill="none"
                    stroke="#5a5a5a"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="m296 352l-96-96l96-96"
                  />
                </svg>
              </BackBtn>
              <Heading>
                <Title>Great!</Title>
                <Subtitle style={{ color: `${error ? "#EB5757" : "black"} ` }}>
                  {error ? error : "Click to edit any of the parameters"}
                </Subtitle>
                <form onSubmit={handleSubmit}>
                  <Input
                    placeholder="https://link_to_your_application_here.com"
                    // defaultValue={useSelector((state) => state.link)}
                    {...link}
                  />
                  <Input2 placeholder="Software Engineering Intern" {...role} />
                  <Input2 placeholder="Facebook" {...company} />
                  <Input2
                    placeholder="Deadline: 12/01/20"
                    type="date"
                    {...deadline}
                  />
                  <Input2 placeholder="Menlo Park, California" {...location} />
                  <TextAreaDiv>
                    <Textarea
                      placeholder="As an intern, you'll become an expert on the Facebook Terminal and gain a deeper understanding of technology and finance. In addition to your projects, you'll participate in coding challenges, attend tech talks and network with other interns."
                      {...description}
                    />
                  </TextAreaDiv>
                  <Subtitle>What stage are you in applying?</Subtitle>
                  <ApplicationButtonContainer
                    drop={drop}
                    onClick={() => setDrop(!drop)}
                  >
                    {slider !== undefined ? (
                      <>
                        <ApplicationButton color={colors[slider]}>
                          {svgOrder[slider]}
                          {statusConverter[slider]}
                        </ApplicationButton>
                      </>
                    ) : (
                      <>
                        <div>Select Your Status</div>
                        <div
                          style={{
                            display: "flex",
                            transform: `${drop ? "rotate(-180deg)" : ""}`,
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "3px",
                            marginLeft: "5px",
                          }}
                        >
                          {downArrowSvg}
                        </div>
                      </>
                    )}

                    {drop && (
                      <ButtonDropDown
                        setButtonDrop={setDrop}
                        setUpdatedStatus={setSlider}
                      ></ButtonDropDown>
                    )}
                  </ApplicationButtonContainer>
                  <SubmitButton
                    primary
                    value="Finish adding application"
                    style={{ margin: "30px auto" }}
                  ></SubmitButton>
                </form>
              </Heading>
            </ContentDiv>
          </BackgroundDiv>
        </div>
      </Fragment>
    );
  }
}
function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    setValue(e.target.value);
  }
  console.log(value);
  return { value, onChange: handleChange };
}
