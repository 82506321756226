import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../../styles/shared";
export default function PostPopup({
  popup,
  popupRef,
  position,
  updatedStatus,
  company,
}) {
  const history = useHistory();
  const titleMap = {
    0: "Sounds Interesting!",
    1: "Good Luck!",
    2: "Good Luck!",
    3: "Congrats!",
    4: "Congrats!",
    5: "What Happened?",
    6: "What Happened?",
  };
  console.log(updatedStatus);
  return (
    <Popup ref={popupRef} active={popup}>
      <p style={{ margin: "4px", textAlign: "center" }}>
        {titleMap[parseInt(updatedStatus)]}
      </p>
      <Button
        onClick={() =>
          history.push({
            pathname: "mainfeed",
            state: {
              application: JSON.stringify({
                position: position,
                app_status: updatedStatus.toString(),
                company: company,
              }),
            },
          })
        }
        style={{ fontSize: "14px", padding: "4px 8px" }}
        primary
      >
        Share
      </Button>
    </Popup>
  );
}
const Popup = styled.div`
  position: absolute;
  top: -80px;
  width: 100%;
  height: 80px;
  transition: 0.3s ease;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 5px 13px -7px #5c5c5c,
    5px 5px 15px 5px rgba(100, 100, 100, 0.1);
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  ${(props) =>
    props.active &&
    `
  opacity:1;
  pointer-events:auto;

  `};
`;
