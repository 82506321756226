import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "../../../../styles/shared";
import party from "party-js";
import ButtonDropDown from "./ButtonDropDown";
import { ContentGrid, ContentColumn, Content } from "./style";
import { firestore, auth } from "../../../../firebaseSetup";
import firebase from "../../../../firebaseSetup";
import { ApplicationButton } from "../../../../styles/shared";
import PostPopup from "./PostPopup";
export default function Application({
  link,
  app_status,
  company,
  position,
  id,
  deadline,
  location,
  description,
  notes,
  date_updated,
  getJobs,
}) {
  const popupRef = useRef();
  const dropDownRef = useRef();
  const firstRender = useRef(true);
  const [clicked, setClicked] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState(notes);
  const [updatedStatus, setUpdatedStatus] = useState(app_status);
  const [splitDescription, setSplitDescription] = useState([
    description.substring(0, 100),
    description.substring(100, description.length),
  ]);
  const [time, setTime] = useState(date_updated);
  const [popup, setPopup] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [buttonDrop, setButtonDrop] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [buttonColor, setButtonColor] = useState([
    "#6175A9",
    "#61A5A9",
    "#A21660",
    "#9FA216",
    "#61A975",
    "#A99161",
    "#A96199",
  ]);
  const [buttonRef, setButtonRef] = useState();
  const [readMore, setReadMore] = useState(false);
  const debouncedNotes = useDebounce(updatedNotes);
  const debouncedStatus = useDebounce(updatedStatus);

  const history = useHistory();
  const buttonSvg1 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g transform="translate(24 0) scale(-1 1)">
        <path
          fill="white"
          d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"
        />
        <path
          fill="white"
          d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"
        />
      </g>
    </svg>
  );
  const buttonSvg2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m4 12l6 6L20 6"
      />
    </svg>
  );
  const buttonSvg3 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="white"
        d="M14.755 15a2.249 2.249 0 0 1 2.248 2.249v.575c0 .895-.32 1.76-.9 2.439C14.532 22.096 12.145 23 9 23s-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.434v-.578A2.249 2.249 0 0 1 3.253 15h11.502Zm0 1.5H3.252a.749.749 0 0 0-.75.749v.578c0 .535.192 1.053.54 1.46c1.253 1.468 3.22 2.214 5.957 2.214c2.739 0 4.706-.746 5.963-2.214a2.25 2.25 0 0 0 .54-1.463v-.575a.749.749 0 0 0-.748-.749Zm4.3-15.096a.75.75 0 0 1 1.023.279A12.694 12.694 0 0 1 21.75 8c0 2.254-.586 4.424-1.683 6.336a.75.75 0 1 1-1.301-.746A11.194 11.194 0 0 0 20.25 8c0-1.983-.513-3.89-1.475-5.573a.75.75 0 0 1 .28-1.023ZM9 3.004a5 5 0 1 1 0 10a5 5 0 0 1 0-10Zm6.589.396a.75.75 0 0 1 1.023.28A8.713 8.713 0 0 1 17.75 8a8.72 8.72 0 0 1-1.143 4.328a.75.75 0 1 1-1.304-.743A7.213 7.213 0 0 0 16.25 8a7.213 7.213 0 0 0-.942-3.578a.75.75 0 0 1 .28-1.022ZM9 4.505a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7Z"
      />
    </svg>
  );
  const buttonSvg4 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="white"
        d="M1023.31 473.28c-1.36-11.312-5.614-21.713-12.095-30.465h.16l-.88-.88c-3.28-4.32-7.12-8.128-11.408-11.504L831.935 262.047V158.783c0-17.68-14.32-32-32-32H698.607l-116.8-99.711c-36.223-36.224-99.504-36.289-135.776 0l-116.4 99.711H223.935c-17.68 0-32 14.32-32 32V264.16L28.815 427.28c-17.343 11.472-28.88 31.088-28.88 53.344v479.504c0 35.184 28.817 64 64 64h896c35.184 0 64-28.816 64-64v-478.24c.224-2.88-.064-5.76-.624-8.607zM268.865 725.503L63.937 915.582V544.686zm61.697 29.999a31.634 31.634 0 0 0 11.377-8.224c1.712-1.935 3.056-4.063 4.224-6.255l137.904-127.936c7.248-5.84 15.84-8.913 24.88-8.913c9.183 0 18.367 3.216 24.527 8l383.84 347.936H110.002zm427.777-25.776l201.6-178.896v361.632zm139.599-313.104h-.976l54.896 55.792l-119.92 106.432V350.638zM491.283 72.318c6.064-6.032 14.095-9.376 22.623-9.376c8.56 0 16.592 3.344 22.656 9.376l64.624 54.464H426.754zm276.655 118.464V635.63l-57.312 50.88l-136.32-123.568c-37.68-29.536-91.775-30.817-131.68 1.376l-126.624 117.44l-60.064-53.008V190.782zM103.777 442.813l88.16-88.129V572.3L75.313 469.404l26.992-26.592h1.472z"
      />
    </svg>
  );
  const buttonSvg7 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill="white" d="M7 10h10v4H7z" />
      <path
        fill="white"
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"
      />
    </svg>
  );
  const buttonSvg6 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path
        fill="white"
        d="M256 16C123.452 16 16 123.452 16 256s107.452 240 240 240s240-107.452 240-240S388.548 16 256 16Zm147.078 387.078a207.253 207.253 0 1 1 44.589-66.125a207.332 207.332 0 0 1-44.589 66.125Z"
      />
      <path
        fill="white"
        d="M152 200h40v40h-40zm168 0h40v40h-40zm-64 88a88.1 88.1 0 0 0-88 88h32a56 56 0 0 1 112 0h32a88.1 88.1 0 0 0-88-88Z"
      />
    </svg>
  );
  const buttonSvg5 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="white"
        d="M11 6h3l3.29-3.3a1 1 0 0 1 1.42 0l2.58 2.59a1 1 0 0 1 0 1.41L19 9h-8v2a1 1 0 0 1-1 1a1 1 0 0 1-1-1V8a2 2 0 0 1 2-2m-6 5v4l-2.29 2.29a1 1 0 0 0 0 1.41l2.58 2.59a1 1 0 0 0 1.42 0L11 17h4a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h-7v1a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V9Z"
      />
    </svg>
  );

  const svgOrder = [
    buttonSvg1,
    buttonSvg2,
    buttonSvg3,
    buttonSvg4,
    buttonSvg5,
    buttonSvg6,
    buttonSvg7,
  ];

  const editSvg = (
    <svg
      style={{ paddingRight: "0 4px" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 36 36"
    >
      <path
        fill="#5a5a5a"
        d="m4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4Z"
        class="clr-i-solid clr-i-solid-path-1"
      />
      <path
        fill="#5a5a5a"
        d="m33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93Z"
        class="clr-i-solid clr-i-solid-path-2"
      />
      <path fill="none" d="M0 0h36v36H0z" />
    </svg>
  );
  const deleteSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="#5a5a5a"
        d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm10.618-3L15 2H9L7.382 4H3v2h18V4z"
      />
    </svg>
  );
  const linkSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 64 64"
    >
      <path
        fill="#5a5a5a"
        d="m32.057 40.697l-12.96 12.96c-.989.988-2.714.886-3.831-.232l-4.69-4.691c-1.119-1.117-1.222-2.842-.233-3.831l12.961-12.96c.99-.991.884-2.715-.232-3.832c-1.117-1.118-2.84-1.222-3.831-.231L2.68 44.438c-.989.989-.887 2.713.232 3.831l12.819 12.819c1.114 1.117 2.842 1.221 3.83.232l16.561-16.56c.99-.99.884-2.715-.233-3.831s-2.842-1.223-3.832-.232M61.09 15.73L48.271 2.911c-1.118-1.118-2.842-1.221-3.832-.232L27.88 19.238c-.99.99-.886 2.715.231 3.832c1.116 1.116 2.843 1.221 3.832.233l12.958-12.96c.992-.991 2.716-.885 3.833.232l4.69 4.691c1.117 1.118 1.222 2.841.231 3.832l-12.959 12.96c-.99.989-.884 2.715.232 3.833c1.117 1.116 2.842 1.221 3.833.231l16.558-16.56c.993-.989.886-2.716-.229-3.832"
      />
      <path
        fill="#5a5a5a"
        d="M20.302 43.7c1.116 1.118 2.84 1.22 3.828.231L43.932 24.13c.99-.99.888-2.711-.231-3.83c-1.116-1.118-2.84-1.223-3.832-.231l-19.8 19.8c-.991.989-.886 2.714.233 3.831"
      />
    </svg>
  );
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        dropDown &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (popup && popupRef.current && !popupRef.current.contains(e.target)) {
        setPopup(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [popup]);

  const extend = (e) => {
    e.stopPropagation();
    setClicked((prevClicked) => !prevClicked);
  };
  const handlePopup = (newTime) => {
    // handle popup logic on update
    setPopup(true);
    localStorage.setItem(
      "lastPopupDate",
      JSON.stringify(newTime.toDate().getTime())
    );
    //setPopup(true);
    // update localstorage
  };
  // move checkifclickedoutside to hook
  // popup check ifclickoutside
  // have x button
  // if button to update, history.push(mainfeed, {position, status})
  // make sure new status is pushed!!!

  const getStatus = () => {
    switch (updatedStatus.toString()) {
      case "0":
        return "Interested";
      case "1":
        return "Applied";
      case "2":
        return "Interviewed";
      case "3":
        return "Offered";
      case "4":
        return "Accepted";
      case "5":
        return "Rejected";
      case "6":
        return "Declined";
    }
  };

  const update = async (newNotes, newStatus) => {
    try {
      const newTime = firebase.firestore.Timestamp.now();
      const update_res = firestore
        .collection(`jobs/${auth.currentUser.uid}/jobs`)
        .doc(id)
        .update({
          link: link,
          position: position,
          company: company,
          deadline: deadline,
          location: location,
          description: description,
          app_status: newStatus,
          date_updated: newTime,
          notes: newNotes,
        })
        .then(() => {
          setTime(newTime);
          console.log(newStatus);
          if (newStatus === 3 || newStatus === 4) {
            if (buttonRef) {
              party.confetti(buttonRef, {
                count: party.variation.range(45, 55),
                spread: party.variation.range(15, 50),
                speed: 650,
                color: () =>
                  party.Color.fromHsl(
                    party.random.randomRange(0, 360),
                    100,
                    50
                  ),
                shapes: [
                  "square",
                  "rectangle",
                  "roundedSquare",
                  "roundedRectangle",
                  "circle",
                ],
              });
            }
          }
          if (app_status != newStatus) {
            const lastPopupDate = JSON.parse(
              localStorage.getItem("lastPopupDate")
            );
            if (lastPopupDate !== null) {
              console.log(typeof lastPopupDate);
              const diff =
                Math.abs(newTime.toDate().getTime() - lastPopupDate) / 3600000; // get hour diff between times
              if (diff >= 24) {
                handlePopup(newTime);
              }
            } else {
              handlePopup(newTime);
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteApplication = async (event, id) => {
    try {
      const delete_res = await firestore
        .collection(`jobs/${auth.currentUser.uid}/jobs`)
        .doc(id)
        .delete()
        .then(() => {
          getJobs();
        });
      event.preventDefault();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (
      !firstRender.current &&
      (debouncedNotes !== notes || app_status !== debouncedStatus)
    ) {
      update(debouncedNotes, debouncedStatus);
    }
  }, [debouncedNotes, debouncedStatus]);

  return (
    <div style={{ display: `${deleted ? "none" : "block"}` }}>
      <Content clicked={clicked} onClick={(e) => extend(e)}>
        <ContentGrid>
          <ContentColumn>{position}</ContentColumn>
          <ContentColumn>{company}</ContentColumn>
          <ContentColumn fontSize="14px">
            {time.toDate().toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            <br />
            {time.toDate().toLocaleTimeString(undefined, {
              hour: "numeric",
              minute: "numeric",
            })}
          </ContentColumn>
          <ContentColumn
            style={{ position: "relative" }}
            onClick={(e) => e.stopPropagation()}
          >
            <ApplicationButton
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              drop={buttonDrop}
              color={buttonColor[updatedStatus]}
              onClick={() => setButtonDrop(!buttonDrop)}
              ref={setButtonRef}
            >
              {svgOrder[updatedStatus]}{" "}
              <span style={{ marginLeft: "5px" }}>{getStatus(app_status)}</span>
              {buttonDrop && (
                <ButtonDropDown
                  color={
                    buttonColor[updatedStatus ? updatedStatus : app_status]
                  }
                  setUpdatedStatus={setUpdatedStatus}
                  setButtonDrop={setButtonDrop}
                  class={"button--dropdown"}
                />
              )}
            </ApplicationButton>
            <PostPopup
              popupRef={popupRef}
              popup={popup}
              position={position}
              updatedStatus={updatedStatus}
              company={company}
            />
          </ContentColumn>
          <ContentColumn>
            <ButtonBox style={{ position: "relative" }}>
              <svg
                onClick={() => setDropDown(true)}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#175596"
                  d="M3 9.5a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3z"
                />
              </svg>
              {dropDown && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "-70px",
                    zIndex: "10",
                    right: 0,
                    width: "170px",
                    backgroundColor: "#fff",
                    boxShadow:
                      "0px 5px 13px -7px #5c5c5c, 5px 5px 15px 5px rgba(100,100,100,0.1)",
                  }}
                  ref={dropDownRef}
                  onClick={(e) => e.stopPropagation()}
                >
                  <RBtn
                    id="edit"
                    onClick={() =>
                      history.push({
                        pathname: `/tracker/edit/information`,
                        state: id,
                      })
                    }
                  >
                    {editSvg}
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      Edit row
                    </span>
                  </RBtn>
                  <RBtn onClick={(event) => deleteApplication(event, id)}>
                    {deleteSvg}
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      Delete row
                    </span>
                  </RBtn>
                  {link && (
                    <ExternalLink
                      href={link}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <RBtn>
                        {linkSvg}
                        <span
                          style={{
                            display: "block",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}
                        >
                          View Application
                        </span>
                      </RBtn>
                    </ExternalLink>
                  )}
                </div>
              )}
            </ButtonBox>
          </ContentColumn>
        </ContentGrid>
        <Extra clicked={clicked}>
          <Subtitle>
            Deadline:{" "}
            {deadline.toDate().toLocaleDateString() ===
            new Date(0).toLocaleDateString()
              ? "N/A"
              : deadline.toDate().toLocaleDateString()}
          </Subtitle>
          <Subtitle>Location: {location ? `${location}` : "N/A"}</Subtitle>

          <Notes
            onClick={(e) => e.stopPropagation()}
            id="textarea"
            placeholder="Notes: 
              Personal application log notes go here. The user can talk about things privately without sharing here. "
            onChange={(e) => setUpdatedNotes(e.target.value)}
          >
            {notes}
          </Notes>
          <Description
            onClick={(e) => e.stopPropagation()}
            style={{ fontWeight: 400, width: "98%" }}
          >
            {splitDescription[0]}
            {readMore && splitDescription[1]}
            {splitDescription[1] && (
              <ReadMore
                onClick={(e) => {
                  e.stopPropagation();
                  setReadMore(!readMore);
                }}
                style={{ marginLeft: `${!readMore ? "0px" : "3px"}` }}
              >
                {!readMore && (
                  <span style={{ fontWeight: 400, marginLeft: "3px" }}>
                    ...
                  </span>
                )}
                {!readMore ? "Read More" : " Read Less"}
              </ReadMore>
            )}
          </Description>
        </Extra>
      </Content>
    </div>
  );
}

function useDebounce(notes, delay = 500) {
  const [debounced, setDebounced] = useState(notes);
  useEffect(() => {
    const timer = setTimeout(() => setDebounced(notes), delay);
    return () => clearTimeout(timer);
  }, [notes, delay]);
  return debounced;
}

const Slider = styled.input`
  height: 5px;
`;

const Status = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */
  color: #5c5c5c;
  margin: 2px auto auto 10px;
`;

const ButtonBox = styled.div`
  margin: auto;
  display: block;
`;

const Subtitle = styled.p`
  margin: 4px;
  font-size: 12px;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: #5c5c5c;
  font-weight: 600;
  transition: 0.3s ease;
  &:hover {
    color: #175596;
  }
`;

const RBtn = styled.button`
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  //font-weight: 600;
  font-size: 11px;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  width: 100%;
  height: 40px;
  letter-spacing: 1px;
  text-decoration: none;
  :hover {
    background: #ededed;
  }
  transition: all 0.3s ease;
`;

const LinkBtn = styled.a`
  text-decoration: none;
  transition: all 0.3s ease;
`;

const Extra = styled.div`
  margin-left: 40px;
  overflow: hidden;
  opacity: 0;
  max-height: 0px;
  transition: all 0.3s ease-out;
  ${({ clicked }) =>
    clicked &&
    css`
      max-height: 100%;
      opacity: 1;
    `}
`;

const Top = styled.div`
  display: flex;
  /* height: 100px; */
`;

const Topline = styled.div`
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
`;

const TopText = styled.div`
  color: #5a5a5a;
  margin: 0px 3vw;
  font-size: 13px;
`;

const Description = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 23px;
  padding: 10px 0px 10px 0px;
  color: #5a5a5a;
  &:hover {
    cursor: text;
  }
`;

const ReadMore = styled.span`
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
`;

const Notes = styled.textarea`
  margin-top: 15px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid #8191ba;
  box-sizing: border-box;
  border-radius: 14px;
  resize: none;
  padding: 10px;
  width: 99%;
  min-height: 80px;
  font-size: 12px;
  line-height: 15px;
  /* RISE colors high fidelity */
  color: #5a5a5a;
  border-radius: 10px;
  outline: none;
`;

const Svg = styled.img`
  height: 50%;
`;
