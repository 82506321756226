import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseSetup";
export const AuthenticationContext = createContext();

export const AuthenticationProvider = (props) => {
  const [authentication, setAuthentication] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("loggedIn"))
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (currentuser) {
        console.log("logged in");
        setAuthentication(currentuser);
        localStorage.setItem("loggedIn", JSON.stringify(true));
        setIsLoggedIn(true);
      } else {
        setAuthentication(null);
        localStorage.setItem("loggedIn", JSON.stringify(false));
        setIsLoggedIn(false);
      }
    });
    return unsubscribe();
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{ authentication, setAuthentication, isLoggedIn, setIsLoggedIn }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};
