import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/analytics";
import ReactGA from "react-ga4";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
/*
development
firebase.initializeApp({
  apiKey: "AIzaSyBTw_6a158TZfLfxVbDL6RbakNND46vWpU",
  authDomain: "mypipeline-development.firebaseapp.com",
  projectId: "mypipeline-development",
  storageBucket: "mypipeline-development.appspot.com",
  messagingSenderId: "747925646972",
  appId: "1:747925646972:web:55a1a8bff467e41845a3f9",
  measurementId: "G-9XS2ZLL67R",
});
*/
const firebaseConfig = {
  apiKey: "AIzaSyAzSnfRFzcWv31bzucNFUyOqPuWv61MYG4",
  authDomain: "mypipeline-production.firebaseapp.com",
  projectId: "mypipeline-production",
  storageBucket: "mypipeline-production.appspot.com",
  messagingSenderId: "852342635793",
  appId: "1:852342635793:web:d58f89272f5e4fa57ea0b0",
  measurementId: "G-N80Y7EHWHE",
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const analytics = firebase.analytics();
export const measurementId = firebaseConfig.measurementId;
// const perf = firebase.performance();

export default firebase;
